/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { userRoles } from "../../../../../_component/constant";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();

  const { rouvrDefaultRole, permissions } = useSelector(({ auth }) => ({
    rouvrDefaultRole: auth.user ? auth.user.rouvrDefaultRole : null,
    permissions: auth.permissions,
  }));

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const menuItems = [
    {
      title: "Template",
      key: "TEMPLATE",
      url: "/templates",
      icon: "template.svg",
    },
    {
      title: "Category",
      key: "CATEGORY",
      url: "/categories",
      icon: "category.svg",
    },
  ];

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {menuItems.map((menuItem) => {
          const key = `${menuItem.key}_LIST`;

          return (
            (rouvrDefaultRole === userRoles.superAdmin ||
              (permissions && permissions.includes(key))) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  menuItem.url,
                  false
                )}`}
                aria-haspopup="true"
                key={key}
              >
                <NavLink className="menu-link" to={menuItem.url}>
                  <span className="svg-icon svg-icon-md mr-3">
                    <SVG
                      src={toAbsoluteUrl(
                        `/media/svg/user-authorization/${menuItem.icon}`
                      )}
                    />
                  </span>
                  <span className="menu-text">{menuItem.title}</span>
                </NavLink>
              </li>
            )
          );
        })}
        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
